<template>
  <div class="MERCH">
    <div class="merch-card scorr-roll">
      <!-- 搜索列 -->
      <div class="merch-search">
        <div class="search-box">
          <div class="sch-1 sch-2">
            <div class="sch-title">资质状态:</div>
            <el-select
              v-model="checkApi.select"
              placeholder="请选择"
              @change="checkApi.page = 1"
            >
              <el-option
                v-for="item in QaOptions"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </div>
          <div class="sch-1 sch-2">
            <div class="sch-title">证件类型:</div>
            <el-input
              placeholder="请输入类型"
              v-model="checkApi.keyWord"
              @change="checkApi.page = 1"
            ></el-input>
          </div>
          <div class="sch-1 sch-2">
            <el-button type="primary" size="mini" @click="toSearch">
              查 询
            </el-button>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div class="merch-table scorr-roll">
        <el-table :data="aptitudeList.data" border style="width: 100%">
          <el-table-column prop="template_id" label="编号" align="center">
          </el-table-column>
          <el-table-column label="证件类型" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.name"
                placement="bottom"
              >
                <div class="exceed">
                  {{ row.name ? row.name : "/" }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="是否需要提供资质"
            align="center"
          >
            <template slot-scope="{ row }">
              <div class="userBtn">
                <el-switch
                  @change="changeSwith(row)"
                  v-model="row.ID"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
                <!-- <div v-if="row.id != 0">是</div>
                <div @click="changeSwith(row)" v-else>否</div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="aptitudeList.per_page"
          layout="total, prev, pager, next, jumper"
          :total="aptitudeList.total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="dio-input">
      <el-dialog title="新建企业" :visible.sync="IsAddEnterprise" width="600px">
        <div>
          <el-form ref="form" label-width="150px">
            <el-form-item label="企业类型名称">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-radio-group v-model="form.radio">
                <el-radio :label="3">启用</el-radio>
                <el-radio :label="6">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="IsAddEnterprise = false">取 消</el-button>
          <el-button type="primary" @click="IsAddEnterprise = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
export default {
  data() {
    return {
      value: "",
      QaOptions: [
        {
          code: "",
          name: "请选择",
        },
        {
          code: 0,
          name: "关",
        },
        {
          code: 1,
          name: "开",
        },
      ],
      searchData: {},
      activeName: 0,
      IsAddEnterprise: false,
      form: {},
      aptitudeList: {},
      checkApi: {
        page: 1,
        select: "",
      },
    };
  },
  created() {
    if (
      this.$route.query.is_unity != undefined &&
      this.$route.query.type_id != undefined
    ) {
      this.checkApi.type_id = this.$route.query.type_id;
      this.checkApi.is_unity = this.$route.query.is_unity;
      this.toSearch();
    }
  },
  methods: {
    ...mapActions(["gettemplateList", "postchangeSelect"]),
    // 改变状态
    async changeSwith(row) {
      let newApi = {
        type_id: this.$route.query.type_id,
        is_unity: this.$route.query.is_unity,
        template_id: row.template_id,
      };
      if (row.id == 0) {
        newApi.action = "add";
      } else {
        newApi.action = "delete";
      }
      let data = await this.postchangeSelect(newApi);
      if (data.code == 200) {
        this.$message({
          message: data.message,
          type: "success",
        });
        this.toSearch();
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 翻页
    handleCurrentChange(e) {
      this.checkApi.page = e;
      this.toSearch();
    },
    // 获取列表
    async toSearch() {
      let data = await this.gettemplateList(this.checkApi);
      data.data.data.forEach((item) => {
        //console.log(item);
        if (item.id != 0) {
          item.ID = true;
        } else {
          item.ID = false;
        }
      });
      this.aptitudeList = data.data;
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px;
  }
}
.el-tabs__item.is-active {
  color: #06B7AE;
}
.el-tabs__item:hover {
  color: #06B7AE;
}
.el-tabs__active-bar {
  background-color: #06B7AE;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      height: 50px;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
</style>
